import { EmployeeInterface } from '@src/interfaces/employees'
import React, { useState } from 'react'
import { tabsCategory } from '@components/AnytimeFeedback/AnytimeFeedbackSidebar'
import {
  AnytimeFeedbackListInterface,
  FeedbackCategory,
} from '@src/interfaces/anytimeFeedback'
import { Box, ErrorWidget, Flex, ToggleButton } from '@revolut/ui-kit'
import { AnytimeFeedbackCard } from '@components/AnytimeFeedback/AnytimeFeedbackCard'
import AddBubbles from '@src/assets/3D/AddBubbles@2x.png'

interface Props {
  data: EmployeeInterface
  performanceContent?: React.ReactNode
  feedback?: AnytimeFeedbackListInterface
  refetchFeedbackList: () => void
  initialTabId?: tabsCategory
}

export const FeedbackSection = ({
  data,
  performanceContent,
  feedback,
  refetchFeedbackList,
  initialTabId,
}: Props) => {
  const [currentTabId, setCurrentTabId] = useState<tabsCategory>(
    initialTabId || tabsCategory.Performance,
  )
  const feedbackList = feedback?.results || []

  const tabs = [
    {
      title: 'Performance review',
      id: tabsCategory.Performance,
      list: [],
      type: FeedbackCategory.Performance,
    },
    {
      title: '1:1 notes',
      id: tabsCategory.OneToOne,
      list: feedbackList.filter(item => item.category === FeedbackCategory.OneToOne),
      type: FeedbackCategory.OneToOne,
    },
    {
      title: 'Anytime',
      id: tabsCategory.Anytime,
      list: feedbackList.filter(item => item.category === FeedbackCategory.Anytime),
      type: FeedbackCategory.Anytime,
    },
  ]

  const currentTab = tabs.find(tab => tab.id === currentTabId)
  const isPerformance = currentTab?.type === FeedbackCategory.Performance
  const hasPerformanceContent = isPerformance && !!performanceContent

  return (
    <Box>
      <Box mb="s-16">
        <Flex gap="s-8">
          {tabs.map(tab => (
            <ToggleButton
              key={tab.id}
              onClick={() => setCurrentTabId(tab.id)}
              checked={currentTab?.id === tab.id}
            >
              {tab.title}
            </ToggleButton>
          ))}
        </Flex>
      </Box>
      {isPerformance && performanceContent}
      {currentTab?.list.map(item => (
        <AnytimeFeedbackCard
          key={item.id}
          feedback={item}
          refreshFeedbackCard={refetchFeedbackList}
          employeeData={data}
          type={currentTab.type}
          withBorder
        />
      ))}
      {!hasPerformanceContent && !currentTab?.list.length && (
        <ErrorWidget>
          <ErrorWidget.Image src={AddBubbles} />
          <ErrorWidget.Title>Your feedback will appear here</ErrorWidget.Title>
        </ErrorWidget>
      )}
    </Box>
  )
}
